import React from "react";
import { isEmpty } from "lodash";
import I18n from "i18n-js";
import { FormError, Errors } from "./errors";
import { ControlGroup } from "./control_group";

export const FormKey: React.FunctionComponent<{ value: string; errors: FormError[] }> = (props) => {
  const hasErrors = isEmpty(props.errors);

  return (
    <ControlGroup errors={props.errors}>
      <label htmlFor="roqua_organization_key">
        <h5>{I18n.t("project.roqua_organization_key")}</h5>
        <p>{I18n.t("project.roqua_organization_key_description")}</p>
      </label>
      <div className="controls">
        <input type="text" name="roqua_organization_key" value={props.value} readOnly />
        <Errors errors={props.errors} />
      </div>
    </ControlGroup>
  );
};
