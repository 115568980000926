import React from "react";
import classNames from "classnames";
import { Icon } from "../Icon";
import I18n from "i18n-js";

interface Props {
  password: string;
  passwordConfirmation: string;
}

export const PasswordConfirmationValidation: React.FunctionComponent<Props> = ({ password, passwordConfirmation }) => {
  const confirmed = password === passwordConfirmation;
  const className = classNames("password-confirmation", { confirmed });

  return <li className={className}>
    <Icon name={`${confirmed ? 'check' : 'times'}-circle`} />

    {confirmed ?
      <p>{I18n.t('user.account.passwords_match')}</p> :
      <p>{I18n.t('user.account.passwords_dont_match')}</p>
    }
  </li>;
}

