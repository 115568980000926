import React from "react";
import classNames from "classnames";
import { isEmpty } from "lodash";
import I18n from "i18n-js";

interface Option {
  label: string;
  value: any;
}

interface Input {
  name: string;
  value: any;
  options?: Option[];
}

interface Label {
  text: string;
  description?: string;
}

type Supportive = string[] | string | undefined;

export interface CommonFieldProps {
  optional?: boolean;
  errors?: Supportive;
  input: Input;
  label: Label;
  hints?: Supportive;
  disabled?: boolean;
  onValueUpdate: (value: string) => void;
}

interface Props extends CommonFieldProps {
  type: "text" | "email" | "textarea" | "select" | "tel";
}

const SupportiveText: React.FunctionComponent<{ text: Supportive }> = ({ text }) => {
  if (!text || isEmpty(text)) {
    return null;
  } else {
    return <span className="help-inline">{text}</span>;
  }
};

export const GenericField: React.FunctionComponent<Props> = (props) => {
  const { optional = false, errors = [] } = props;

  const className = classNames("control-group", { error: !isEmpty(errors) });

  const renderTextArea = () => {
    return <textarea id={props.input.name} name={props.input.name} onChange={(e) => props.onValueUpdate(e.target.value)} disabled={props.disabled} defaultValue={props.input.value} />;
  };

  const renderInputField = () => {
    return (
      <input
        type={props.type}
        id={props.input.name}
        name={props.input.name}
        defaultValue={props.input.value}
        className={"span3"}
        onChange={(e) => props.onValueUpdate(e.target.value)}
        disabled={props.disabled}
      />
    );
  };

  const renderSelect = () => {
    const options = (props.input.options || []).map((o) => {
      return (
        <option key={o.value} value={o.value}>
          {o.label}
        </option>
      );
    });

    return (
      <select id={props.input.name} name={props.input.name} className={"span3"} disabled={props.disabled} defaultValue={props.input.value} onChange={(e) => props.onValueUpdate(e.target.value)}>
        {options}
      </select>
    );
  };

  return (
    <div className={className}>
      <label htmlFor={props.input.name} className="control-label">
        <h5>
          {props.label.text}
          {optional && <small>{" " + I18n.t("form.input.optional")}</small>}
        </h5>

        <SupportiveText text={props.hints} />
      </label>
      <div className="controls controls-row">
        {props.type == "textarea" ? renderTextArea() : props.type == "select" ? renderSelect() : renderInputField()}

        <SupportiveText text={errors} />
      </div>
    </div>
  );
};
