import React from "react";
import I18n from "i18n-js";
import { Form } from "./NewProject/form";

interface Props {
  roquaKeys: string[];
}

export const NewProject: React.FunctionComponent<Props> = (props) => {
  return (
    <div className="boxed-group">
      <h4 className="boxed-group-title">{I18n.t("project.new.project_settings")}</h4>
      <div className="boxed-group-inner">
        <Form roquaKeys={props.roquaKeys} />
      </div>
    </div>
  );
};

export default NewProject;
