/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// initialize appsignal before any libraries, not using any libraries
const initializeAppsignal = require("../lib/appsignal").initializeAppsignal;
window.appsignal = initializeAppsignal({
  key: document.head.querySelector("meta[name='appsignal_frontend_key']").content,
  revision: document.head.querySelector("meta[name='app_revision']").content
});

import "core-js/stable";

window.I18n = require("i18n-js");

const jq = require("jquery");
window.jQuery = jq;
window.$ = jq;
require("jquery-ujs");

window.React = require("react");
window.ReactDOM = require("react-dom");
window.createReactClass = require("create-react-class");
window.PropTypes = require("prop-types");
window.React.DOM = {
  a: React.createFactory("a"),
  br: React.createFactory("br"),
  button: React.createFactory("button"),
  div: React.createFactory("div"),
  form: React.createFactory("form"),
  h3: React.createFactory("h3"),
  h4: React.createFactory("h4"),
  h5: React.createFactory("h5"),
  hr: React.createFactory("hr"),
  i: React.createFactory("i"),
  input: React.createFactory("input"),
  label: React.createFactory("label"),
  li: React.createFactory("li"),
  option: React.createFactory("option"),
  p: React.createFactory("p"),
  pre: React.createFactory("pre"),
  select: React.createFactory("select"),
  small: React.createFactory("small"),
  span: React.createFactory("span"),
  strong: React.createFactory("strong"),
  table: React.createFactory("table"),
  tbody: React.createFactory("tbody"),
  td: React.createFactory("td"),
  textarea: React.createFactory("textarea"),
  tfoot: React.createFactory("tfoot"),
  th: React.createFactory("th"),
  thead: React.createFactory("thead"),
  tr: React.createFactory("tr"),
  ul: React.createFactory("ul"),
};

// Carefully constructed regex. this context needs to include file paths without extension, so that the
// react_component view helper can require them. It may or may not also need to include the file paths with
// extension. But it needs to not include the spec files, since in a production setting the devDependencies
// will not be available, which will cause the asset precompilation to fail. (Plus, they'd get included in
// the asset bundle that clients need to download, and that's just needless bloat.)
//
// should match: ./ComponentName
// should match: ./ComponentName.tsx
// should match: ./Folder/ComponentName
// should match: ./Folder/ComponentName.tsx
//
// should NOT match: ./ComponentName.spec.tsx
var componentRequireContext = require.context("components", true, /\.\/([^\.]+)(\.js|\.jsx|\.ts|\.tsx|)$/);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
