import React from "react";
import { includes } from "lodash";
import I18n from "i18n-js";
import { FormErrors } from "./errors";
import { FormTitle } from "./form_title";
import { FormKey } from "./form_key";

export const Form: React.FunctionComponent<{ roquaKeys: string[] }> = (props) => {
  const [errors, setErrors] = React.useState<FormErrors>({ title: [], roqua_organization_key: [] });
  const [title, setTitle] = React.useState("");
  const [key, setKey] = React.useState("");

  const updateTitleAndKey = (title: string) => {
    const newKey = title.toLowerCase().replace(/[^a-z0-9!]+/g, "");

    if (includes(props.roquaKeys, newKey)) {
      setErrors({ title: [], roqua_organization_key: [I18n.t("errors.messages.exists")] });
    } else {
      setErrors({ title: [], roqua_organization_key: [] });
    }

    setTitle(title);
    setKey(newKey);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    jQuery.ajax({
      type: "POST",
      url: "/projects",
      data: {
        project: {
          title: title,
          roqua_organization_key: key,
        },
      },
      success: (project, status, xhr) => {
        const location = xhr.getResponseHeader("Location") || "/projects";
        window.location.replace(location);
      },
      error: (xhr, status, error) => {
        const serverErrors = JSON.parse(xhr.responseText);
        setErrors(Object.assign({}, errors, serverErrors["errors"]));
      },
    });
  };

  const buttonDisabled = title.length === 0 || errors.title.length > 0 || errors.roqua_organization_key.length > 0;

  return (
    <form onSubmit={onSubmit}>
      <FormTitle value={title} onChange={updateTitleAndKey} errors={errors.title} />
      <hr className="invisible" />
      <FormKey value={key} errors={errors.roqua_organization_key} />
      <hr className="invisible" />
      <input className={`btn btn-${buttonDisabled ? "default" : "primary"}`} type="submit" disabled={buttonDisabled} value={I18n.t("project.create.submit")} />
    </form>
  );
};
