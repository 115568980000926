import { isEmpty } from "lodash";
import React from "react";

export type FormError = string;

export interface FormErrors {
  title: FormError[];
  roqua_organization_key: FormError[];
}

export const Errors: React.FunctionComponent<{ errors: FormError[] }> = (props) => {
  const hasErrors = !isEmpty(props.errors);

  if (hasErrors) {
    return <span className="help-inline">{props.errors[0]}</span>;
  } else {
    return null;
  }
};
