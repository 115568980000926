import jQuery from "jquery";
import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
import { includes } from "lodash";

type FlashLevel = "success" | "error";

export function removeAlert() {
  const mountNode = jQuery(".flash-messages")[0];
  ReactDOM.unmountComponentAtNode(mountNode);
}

// Create a Bootstrap flash message and inject it into the page
export function show(message: string, level: FlashLevel, title?: string) {
  // Render react dom to page
  ReactDOM.render(flashDOM(level, title, message), jQuery(".flash-messages")[0]);
  // Make it a bootstrap alert
  (jQuery(".alert") as any).alert();
  setTimeout(removeAlert, 4200);
}

function flashDOM(level: FlashLevel, title: string | undefined, message: string) {
  // Transform level to appropriate bootstrap css class
  const level_class = getLevelClass(level);

  return (
    <div className={classNames("alert", "fade", "in", level_class)}>
      <button className="close" type="button" onClick={removeAlert}>
        ×
      </button>
      <strong>{title}</strong>
      <span>{message}</span>
    </div>
  );
}

function getLevelClass(level: string) {
  if (includes(["error", "warning", "alert"], level)) {
    return "alert-error";
  } else if (includes(["info", "success"], level)) {
    return "alert-#{level}";
  } else {
    return null; // Use the default alert style
  }
}
