import I18n from "i18n-js";

export function role(role: string) {
  if (isEmpty(role)) {
    role = "none";
  }
  return I18n.t(`roles.${role}`, { defaultValue: role });
}

function isEmpty(str: string) {
  return !str || 0 === str.length;
}
