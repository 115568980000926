import React from "react";
import { TextField } from "./form/text_field";
import I18n from "i18n-js";
import { produce, enableES5 } from "immer";
import { EmailField } from "./form/email_field";
import { PhoneField } from "./form/phone_field";
import { SelectField } from "./form/select_field";
import { PasswordField } from "./form/password_field";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { show } from "../lib/flash";

enableES5();

const LOCALES = [
  { value: "nl", label: "Nederlands" },
  { value: "en", label: "English" },
];

interface User {
  locale: string;
  firstname: string | null;
  lastname: string | null;
  email: string | null;
  phone_cell: string | null;
  password?: string;
  password_confirmation?: string;
  current_password?: string;
}

interface UserErrors {
  locale?: string | string[];
  firstname?: string | string[];
  lastname?: string | string[];
  email?: string | string[];
  phone_cell?: string | string[];
  password?: string | string[];
  password_confirmation?: string | string[];
  current_password?: string | string[];
}

interface Props {
  user: User;
}

const MissingCellPhoneWarning: React.FunctionComponent<{ number: string | null | undefined }> = ({ number }) => {
  if (isEmpty(number)) {
    return (
      <div className="alert alert-error">
        <h4>{I18n.t("user.account.missing_phone_cell")}</h4>
        <p>{I18n.t("user.account.missing_phone_cell_description")}</p>
        <p>
          <strong>{I18n.t("user.account.missing_phone_cell_warning")}</strong>
        </p>
        <p>{I18n.t("user.account.missing_phone_cell_warning_description")}</p>
      </div>
    );
  } else {
    return null;
  }
};

function resetPasswordFields(draft: User) {
  draft.current_password = "";
  draft.password = "";
  draft.password_confirmation = "";
}

export const Account: React.FunctionComponent<Props> = (props) => {
  const [user, setUser] = React.useState(props.user);
  const [errors, setErrors] = React.useState<UserErrors>({});
  const submitButtonDisabled = isEmpty(user.current_password);
  const updateUser = (fieldKey: string) => {
    return (value: string) =>
      setUser(
        produce((draft) => {
          draft[fieldKey] = value;
        })
      );
  };
  const submitForm = (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const localeChanged = user.locale !== props.user.locale;

    jQuery.ajax({
      url: "/account",
      data: { user: user },
      type: "PUT",
      dataType: "json",
      beforeSend: () => {
        setErrors({});
      },
      success: () => {
        show(I18n.t("user.account.submit.success"), "success");
        if (localeChanged) window.location.reload();
      },
      error: (xhr) => {
        const userErrors = jQuery.parseJSON(xhr.responseText).errors;
        setErrors(Object.assign(errors, userErrors));
        show(I18n.t("user.account.submit.error"), "error");
      },
      complete: () => setUser(produce(user, resetPasswordFields)),
    });
  };

  return (
    <form>
      <MissingCellPhoneWarning number={props.user.phone_cell} />

      <div className="boxed-group">
        <h4 className="boxed-group-title">Account</h4>
        <div className="boxed-group-inner">
          <TextField
            input={{ name: "firstname", value: user.firstname }}
            label={{ text: I18n.t("user.firstname") }}
            optional={true}
            errors={errors.firstname}
            onValueUpdate={updateUser("firstname")}
          />
          <hr className="invisible" />
          <TextField input={{ name: "lastname", value: user.lastname }} label={{ text: I18n.t("user.lastname") }} optional={true} errors={errors.lastname} onValueUpdate={updateUser("lastname")} />
          <hr className="invisible" />
          <EmailField input={{ name: "email", value: user.email }} label={{ text: I18n.t("user.email") }} optional={false} errors={errors.email} onValueUpdate={updateUser("email")} />
          <hr className="invisible" />
          <PhoneField input={{ name: "phone_cell", value: user.phone_cell }} label={{ text: I18n.t("user.phone_cell") }} errors={errors.phone_cell} onValueUpdate={updateUser("phone_cell")} />
          <hr className="invisible" />
          <SelectField input={{ name: "locale", value: user.locale, options: LOCALES }} label={{ text: I18n.t("user.locale") }} errors={errors.locale} onValueUpdate={updateUser("locale")} />

          <hr />

          <PasswordField
            input={{ name: "", value: user.password }}
            confirmationInput={{ value: user.password_confirmation }}
            label={{ text: I18n.t("user.password"), description: I18n.t("user.password_description") }}
            errors={errors.password}
            strengthIndicator={true}
            confirmation={true}
            onPasswordUpdate={updateUser("password")}
            onConfirmationUpdate={updateUser("password_confirmation")}
          />

          <hr />

          <PasswordField
            input={{ name: "current_password", value: user.current_password }}
            label={{ text: I18n.t("user.current_password"), description: I18n.t("user.current_password_description") }}
            errors={errors.current_password}
            onPasswordUpdate={updateUser("current_password")}
          />

          <hr className="invisible" />

          <input
            type="submit"
            className={classNames("btn", submitButtonDisabled ? "btn-default" : "btn-primary")}
            disabled={submitButtonDisabled}
            onClick={submitForm}
            value={I18n.t("user.account.submit.button")}
          />
        </div>
      </div>
    </form>
  );
};

export default Account;
