import React from "react";
import I18n from "i18n-js";
import { Icon } from "../Icon";
import zxcvbn, { ZXCVBNResult } from "zxcvbn";
import moment from "moment";

// Assuming 200 years == 'never'
function strengthDescription(strength: ZXCVBNResult) {
  const crackTime = moment.duration(strength.crack_times_seconds.online_no_throttling_10_per_second, 'seconds');
  const crackTimeInYears = crackTime.years();
  if (crackTimeInYears > 200) {
    return I18n.t('password_strength_indicator.never')
  } else {
    return I18n.t('password_strength_indicator.crack_time', { crack_time: crackTime.humanize() });
  };
};

interface Props {
  password: string;
}

export const PasswordStrengthIndicator: React.FunctionComponent<Props> = ({ password }) => {
  const strength = zxcvbn(password, ["roqua", "medo", "rom"]);
  const iconName = strength.score == 4 ? 'check-circle' : 'exclamation-circle';

  return <li className="password-strength score-#{@state.score}">
    <Icon name={iconName} />
    <p>
      <span>{I18n.t('password_strength_indicator.strength')}</span>
      <strong className='score'>{I18n.t('password_strength_indicator.value')[strength.score]}</strong>
      <br />
      <small dangerouslySetInnerHTML={{ __html: strengthDescription(strength) }} />
    </p>
  </li>;
}
