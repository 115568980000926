import classNames from "classnames";
import { isEmpty } from "lodash";
import React from "react";
import { FormError } from "./errors";


export const ControlGroup: React.FunctionComponent<{ errors: FormError[] }> = (props) => {
  const hasErrors = !isEmpty(props.errors);
  const className = classNames("control-group", { error: hasErrors });

  return <div className={className}>{props.children}</div>;
};
