import Appsignal from "@appsignal/javascript";
import { AppsignalOptions } from "@appsignal/javascript/dist/cjs/interfaces/options";
import { plugin as pathDecoratorPlugin} from "@appsignal/plugin-path-decorator";
import { plugin as windowEventsPlugin } from "@appsignal/plugin-window-events";

// Default initialization in "development mode", without a key.
// Mutable so that once the app loads, we can update it with one
// that has the key provided through application.js/meta header tags
let appsignal = new Appsignal({});
appsignal.use(pathDecoratorPlugin())
appsignal.use(windowEventsPlugin())

export function initializeAppsignal(opts: AppsignalOptions) {
    const newSignal = new Appsignal(opts);
    newSignal.use(pathDecoratorPlugin())
    newSignal.use(windowEventsPlugin())
    appsignal = newSignal;
    return newSignal;
}

export default appsignal;
