import React from "react";
import { GenericField, CommonFieldProps } from "./generic_field";
import classNames from "classnames";
import I18n from "i18n-js";
import { isEmpty } from "lodash";
import { PasswordStrengthIndicator } from "./password_strength_indicator";
import { PasswordConfirmationValidation } from "./password_confirmation_validation";

// # Options:
// #
// # label                         - [Object] options for the label
// #   text                        - [String] field label text
// #   description                 - [String] field label description
// # input                         - [Object] options for the input
// #   value                       - [String] value of the input, mostly kept in state of the form
// # confirmation                  - [Boolean] whether to show confirmation field
// # confirmationInput             - [Object] options for confirmation field
// #   value                       - [String] value of the confirmation input, mostly kept in state of the form
// # strengthIndicator             - [Boolean] indicates strength of the password
// # errors                        - [Array[Object[String => Array]]] rails errors
// # onPasswordUpdate              - [Function] what function to call on password change
// # onConfirmationUpdate          - [Function] what function to call on password_confirmation change
interface Props extends Omit<CommonFieldProps, "onValueUpdate"> {
  confirmation?: boolean;
  strengthIndicator?: boolean;
  confirmationInput?: { value?: string };
  onPasswordUpdate: (value: string) => void;
  onConfirmationUpdate?: (value: string) => void;
}

export const PasswordField: React.FunctionComponent<Props> = (props) => {
  const inputName = props.input.name || "password";
  const errors = props.errors || [];

  const className = classNames("control-group", "password", { error: !isEmpty(errors) });

  return (
    <div className={className}>
      <label htmlFor={inputName} className="control-label">
        <h5>
          {props.label.text}
          {props.optional && <small>{" " + I18n.t("form.input.optional")}</small>}
        </h5>
        <p>{props.label.description}</p>
      </label>

      <div className="controls controls-row">
        <input
          type="password"
          id={props.input.name}
          name={props.input.name}
          className="span3"
          onChange={(e) => props.onPasswordUpdate(e.target.value)}
          placeholder={props.confirmation ? I18n.t("user.password") : undefined}
        />

        {props.confirmation && (
          <input
            type="password"
            id="password_confirmation"
            name="password_confirmation"
            className="span3"
            onChange={(e) => props.onConfirmationUpdate && props.onConfirmationUpdate(e.target.value)}
            placeholder={I18n.t("user.password_confirmation_alternative")}
          />
        )}

        {!isEmpty(props.errors) && <span className="help-inline">{props.errors}</span>}
      </div>

      {!isEmpty(props.input.value) && (
        <ul className="suggestions">
          {props.strengthIndicator && <PasswordStrengthIndicator password={props.input.value} />}
          {props.confirmation && <PasswordConfirmationValidation password={props.input.value} passwordConfirmation={props.confirmationInput?.value || ""} />}
        </ul>
      )}
    </div>
  );
};
