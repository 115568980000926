import React from "react";
import I18n from "i18n-js";
import { FormError, Errors } from "./errors";
import { ControlGroup } from "./control_group";

export const FormTitle: React.FunctionComponent<{ value: string; errors: FormError[]; onChange: (newTitle: string) => void }> = (props) => {
  return (
    <ControlGroup errors={props.errors}>
      <label htmlFor="title">
        <h5>{I18n.t("project.title")}</h5>
      </label>
      <div className="controls">
        <input type="text" name="title" id="title" value={props.value} onChange={(e) => props.onChange(e.target.value)} />
        <Errors errors={props.errors} />
      </div>
    </ControlGroup>
  );
};
