import I18n from "i18n-js";
import { filter } from "lodash";
import React from "react";
import { role } from "../lib/formatters";
import { Project } from "../lib/models";
import { Icon } from "./Icon";

interface Props {
  projects: Project[];
  canCreateProject: boolean;
}

function filterProjects(projects: Project[], query: string) {
  return filter(projects, (project: Project) => project.title.toLowerCase().indexOf(query.toLowerCase()) >= 0);
}

export const Projects: React.FunctionComponent<Props> = ({ projects, canCreateProject }) => {
  const [query, setQuery] = React.useState("");
  const filteredProjects = filterProjects(projects, query);

  return (
    <div id="projects">
      <div className="clearfix">
        <ProjectsSearchForm onSearch={setQuery} />
        {canCreateProject && <ProjectsCreateButton />}
      </div>

      <ProjectsTable filteredProjects={filteredProjects} query={query} />
    </div>
  );
};

export const ProjectsTable: React.FunctionComponent<{ query: string; filteredProjects: Project[] }> = (props) => {
  return (
    <table id="projects-table" className="table table-striped table-hover table-bordered table-navigatable">
      <thead>
        <tr>
          <th className="span3">{I18n.t("project.title")}</th>
          <th className="span2">{I18n.t("project.status")}</th>
          <th className="span2">{I18n.t("project.role")}</th>
        </tr>
      </thead>

      <tbody>
        {props.filteredProjects.map((project) => (
          <ProjectRow key={project.id} project={project} />
        ))}
      </tbody>

      {props.query.length > 0 && <FilteredProjectsCountRow count={props.filteredProjects.length} />}
    </table>
  );
};

export const ProjectRow: React.FunctionComponent<{ project: Project }> = ({ project }) => {
  return (
    <tr data-url={projectUrl(project)}>
      <td>{project.title}</td>
      <td>{project.active ? I18n.t("project.active") : I18n.t("project.inactive")}</td>
      <td>{role(project.role.role)}</td>
    </tr>
  );
};

function projectUrl(project: Project) {
  if (project.role.role === "researcher") {
    return `/projects/${project.id}`;
  } else {
    return `/projects/${project.id}/people`;
  }
}

export const ProjectsSearchForm: React.FunctionComponent<{
  onSearch: (query: string) => void;
}> = (props) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onSearch(event.target.value);
  };

  return (
    <form className="pull-right">
      <input type="text" placeholder={I18n.t("project.search_in_projects")} onChange={onChange} />
    </form>
  );
};

export const FilteredProjectsCountRow: React.FunctionComponent<{ count: number }> = (props) => {
  return (
    <tfoot>
      <tr>
        <th colSpan={4}>{I18n.t("project.projects_found", { count: props.count })}</th>
      </tr>
    </tfoot>
  );
};

const ProjectsCreateButton: React.FunctionComponent<{}> = () => {
  return (
    <div>
      <a className="btn btn-success" href="/projects/new">
        <Icon name="plus" /> {I18n.t("project.create.link.text")}
      </a>
    </div>
  );
};

export default Projects;
